import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react';
import { getApplications } from '@services/admin.service';

import './application-select.styl';

function ApplicationSelect({ filters, setFilters, availableFeatures }) {
  const { data: applicationsData } = useQuery({
    queryKey: ['applicationsData'],
    queryFn: getApplications,
  });

  const availableIds = useMemo(() => (
    availableFeatures.map(f => f?.properties?.application?.data?.id)
  ), [availableFeatures]);

  function updateSelectionArray(v) {
    if (filters.application.includes(v)) {
      setFilters({ 
        ...filters, 
        application: filters.application.filter(o => o !== v), 
      });
    } else {
      setFilters({ 
        ...filters, 
        application: [...filters.application, v], 
      });
    }
  }

  if (!applicationsData) return null;

  return (
    <ul className="application-select">
      <li 
        className={
          'application-select__option'
          + `${filters.application.includes(2) ? ' application-select__option--selected' : ''}`
          + `${!availableIds.includes(2) ? ' application-select__option--disabled' : ''}`
        }
        onClick={() => updateSelectionArray(2)}
      >
        <span className="application-select__option__text">{applicationsData.find(a => a.id === 2).attributes.name}</span>
      </li>
      <li 
        className={
          'application-select__option'
          + `${filters.application.includes(3) ? ' application-select__option--selected' : ''}`
          + `${!availableIds.includes(3) ? ' application-select__option--disabled' : ''}`
        }
        onClick={() => updateSelectionArray(3)}
      >
        <span className="application-select__option__text">{applicationsData.find(a => a.id === 3).attributes.name}</span>
      </li>
      <li 
        className={
          'application-select__option'
          + `${filters.application.includes(4) ? ' application-select__option--selected' : ''}`
          + `${!availableIds.includes(4) ? ' application-select__option--disabled' : ''}`
        }
        onClick={() => updateSelectionArray(4)}
      >
        <span className="application-select__option__text">{applicationsData.find(a => a.id === 4).attributes.name}</span>
      </li>
      <li 
        className={
          'application-select__option'
          + `${filters.application.includes(1) ? ' application-select__option--selected' : ''}`
          + `${!availableIds.includes(1) ? ' application-select__option--disabled' : ''}`
        }
        onClick={() => updateSelectionArray(1)}
      >
        <span className="application-select__option__text">{applicationsData.find(a => a.id === 1).attributes.name}</span>
      </li>
    </ul>
  )
}

export default ApplicationSelect;