/* eslint-disable max-lines */
import Icon from '@library/Icon/Icon';
import { useMemo } from 'react';
import { getValueChains } from '@services/admin.service';
import { useQuery } from '@tanstack/react-query';

import './value-chain-select.styl';

function ValueChainSelect({ filters, setFilters, availableFeatures }) {
  const availableIds = useMemo(() => (
    availableFeatures.map(f => f?.properties?.value_chain)
  ), [availableFeatures]);

  function updateSelectionArray(v) {
    if (filters['value-chain'].includes(v)) {
      setFilters({ 
        ...filters, 
        'value-chain': filters['value-chain'].filter(o => o !== v), 
      });
    } else {
      setFilters({ 
        ...filters, 
        'value-chain': [...filters['value-chain'], v], 
      });
    }
  }

  const { data: valueChainData } = useQuery({
    queryKey: ['valueChainData'],
    queryFn: getValueChains,
  });

  return (
    <ul className="value-chain-select">
      <li 
        className={
          'value-chain-select__option'
          + `${filters['value-chain'].includes(5) ? ' value-chain-select__option--selected' : ''}`
          + `${!availableIds.includes(5) ? ' value-chain-select__option--disabled' : ''}`
        }
        onClick={() => updateSelectionArray(5)}
      >
        <Icon className="value-chain-select__option__marker" icon="../markers/value-chain-5.png" />
        <span className="value-chain-select__option__text">{valueChainData.find(vc => vc.id === 5).attributes.name}</span>
      </li>
      <li 
        className={
          'value-chain-select__option'
          + `${filters['value-chain'].includes(4) ? ' value-chain-select__option--selected' : ''}`
          + `${!availableIds.includes(4) ? ' value-chain-select__option--disabled' : ''}`
        }
        onClick={() => updateSelectionArray(4)}
      >
        <Icon className="value-chain-select__option__marker" icon="../markers/value-chain-4.png" />
        <span className="value-chain-select__option__text">{valueChainData.find(vc => vc.id === 4).attributes.name}</span>
      </li>
      <li 
        className={
          'value-chain-select__option'
          + `${filters['value-chain'].includes(1) ? ' value-chain-select__option--selected' : ''}`
          + `${!availableIds.includes(1) ? ' value-chain-select__option--disabled' : ''}`
        }
        onClick={() => updateSelectionArray(1)}
      >
        <Icon className="value-chain-select__option__marker" icon="../markers/value-chain-1.png" />
        <span className="value-chain-select__option__text">{valueChainData.find(vc => vc.id === 1).attributes.name}</span>
      </li>
      <li 
        className={
          'value-chain-select__option'
          + `${filters['value-chain'].includes(6) ? ' value-chain-select__option--selected' : ''}`
          + `${!availableIds.includes(6) ? ' value-chain-select__option--disabled' : ''}`
        }
        onClick={() => updateSelectionArray(6)}
      >
        <Icon className="value-chain-select__option__marker" icon="../markers/value-chain-6.png" />
        <span className="value-chain-select__option__text">{valueChainData.find(vc => vc.id === 6).attributes.name}</span>
      </li>
      <li 
        className={
          'value-chain-select__option'
          + `${filters['value-chain'].includes(3) ? ' value-chain-select__option--selected' : ''}`
          + `${!availableIds.includes(3) ? ' value-chain-select__option--disabled' : ''}`
        }
        onClick={() => updateSelectionArray(3)}
      >
        <Icon className="value-chain-select__option__marker" icon="../markers/value-chain-3.png" />
        <span className="value-chain-select__option__text">{valueChainData.find(vc => vc.id === 3).attributes.name}</span>
      </li>
      <li 
        className={
          'value-chain-select__option'
          + `${filters['value-chain'].includes(2) ? ' value-chain-select__option--selected' : ''}`
          + `${!availableIds.includes(2) ? ' value-chain-select__option--disabled' : ''}`
        }
        onClick={() => updateSelectionArray(2)}
      >
        <Icon className="value-chain-select__option__marker" icon="../markers/value-chain-2.png" />
        <span className="value-chain-select__option__text">{valueChainData.find(vc => vc.id === 2).attributes.name}</span>
      </li>
      <li 
        className={
          'value-chain-select__option'
          + `${filters['value-chain'].includes(9) ? ' value-chain-select__option--selected' : ''}`
          + `${!availableIds.includes(9) ? ' value-chain-select__option--disabled' : ''}`
        }
        onClick={() => updateSelectionArray(9)}
      >
        <Icon className="value-chain-select__option__marker" icon="../markers/value-chain-9.png" />
        <span className="value-chain-select__option__text">{valueChainData.find(vc => vc.id === 9).attributes.name}</span>
      </li>
      <li 
        className={
          'value-chain-select__option'
          + `${filters['value-chain'].includes(7) ? ' value-chain-select__option--selected' : ''}`
          + `${!availableIds.includes(7) ? ' value-chain-select__option--disabled' : ''}`
        }
        onClick={() => updateSelectionArray(7)}
      >
        <Icon className="value-chain-select__option__marker" icon="../markers/value-chain-7.png" />
        <span className="value-chain-select__option__text">{valueChainData.find(vc => vc.id === 7).attributes.name}</span>
      </li>
      <li 
        className={
          'value-chain-select__option'
          + `${filters['value-chain'].includes(8) ? ' value-chain-select__option--selected' : ''}`
          + `${!availableIds.includes(8) ? ' value-chain-select__option--disabled' : ''}`
        }
        onClick={() => updateSelectionArray(8)}
      >
        <Icon className="value-chain-select__option__marker" icon="../markers/value-chain-8.png" />
        <span className="value-chain-select__option__text">
          {valueChainData.find(vc => vc.id === 8).attributes.name}
        </span>
      </li>
    </ul>
  )
}

export default ValueChainSelect;