import { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import Icon from '@library/Icon/Icon';
import { useQuery } from '@tanstack/react-query';
import { getPartners } from '@services/admin.service';

import './partner-select.styl';

const chevronDownIcon = () => (
  <Icon icon={faChevronDown} size={{ height: '14px', width: '35px' }} color="#333" />
);

function PartnerSelect({ filters, setFilters, availableFeatures }) {
  const [options, setOptions] = useState([]);
  const { data: partnersData } = useQuery({
    queryKey: ['partnersData'],
    queryFn: getPartners,
  });

  const availableIds = useMemo(() => (
    availableFeatures.flatMap(f => f?.properties?.partners?.data.map(p => p?.id))
  ), [availableFeatures]);

  useEffect(() => {
    if (!partnersData) return;
    setOptions(partnersData.filter(p => availableIds.includes(p?.id)).map(p => (
      { value: p.attributes.name, label: p.attributes.name, id: p?.id }
    )));
  }, [partnersData, availableIds]);

  useEffect(() => {
    if (JSON.stringify({ 
      ...filters, partners: filters.partners.filter(p => availableIds.includes(p?.id)), 
    }) === JSON.stringify(filters)) return;

    setFilters(
      {
        ...filters, 
        partners: filters.partners.filter(p => availableIds.includes(p?.id)), 
      },
    )
  }, [availableIds, filters, setFilters]);

  return (
    <div className="partner-select">
      <Select 
        isMulti
        value={filters.partners}
        options={options}
        onChange={o => setFilters(
          { ...filters, partners: o },
        )}
        className="partner-select__select"
        classNamePrefix="partner-select__select"
        components={{ 
          IndicatorSeparator: () => null,
          ClearIndicator: () => null,
          DropdownIndicator: chevronDownIcon,
        }}
        maxMenuHeight={140}
      />
    </div>
  )
}

export default PartnerSelect;